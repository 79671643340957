<main
  id="content"
  class="content"
  [class]="global.contentDisplay"
  [class.portrait]="global.isPortrait"
  [class.landscape]="global.isLandscape"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.xs="start start"
  fxLayoutAlign.sm="start start"
  (window:resize)="onResizedStudio()"
>
  <content
    fxFlexOrder="1"
    id="text"
    fxFlex.xs="100%"
    fxFlex.md="40%"
    fxFlex.lg="40%"
    fxFlex.xl="40%"
    fxFlexOffset.xs="0"
    fxFlexOffset.md="300px"
    fxFlexOffset.lg="300px"
    fxFlexOffset.xl="300px"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.xs="start center"
    fxLayoutAlign.sm="start center"
  >
    <p *ngIf="global.lang === 'en'" fxFlexOrder="1">
      FisoPole Studio was created in 2013 by the sisters Mayra and Marcia
      Martins with the goal of promoting health, wellness and self-confidence.
      Our biggest concern is that our students feel comfortable and enjoy their
      time with us while evolving in their chosen class. It is located at 521
      Ana Costa Ave. - units 1 and 3, in the heart of Gonzaga neighborhood in
      Santos, next to “Praça da Independência”. There are two acclimatized units
      that are equipped with the best apparatuses for each type of class we
      offer. We have 4m high poles, which can be used for static or spinning
      training, and we have all necessary equipment for different Pilates
      practices - machines, mats, aerial apparatus, as well as other accessories
      like blocks, weights, grips, etc.
    </p>
    <!-- end fxFlexOrder="1.2" en -->

    <p *ngIf="global.lang === 'pt'" fxFlexOrder="1">
      O FisioPole Studio foi criado em 2013 pelas irmãs Mayra e Marcia Martins
      com o objetivo de promover saúde, bem-estar e autoconfiança. Nossa maior
      preocupação é a de que o aluno se sinta confortável e desfrute do seu
      tempo conosco enquanto evolui na modalidade escolhida. Fica localizado na
      Av. Ana Costa, 521 - salas 1 e 3, bem no coração do Gonzaga em Santos,
      próximo à Praça da Independência. São duas salas climatizadas e equipadas
      com os melhores aparelhos para cada tipo de aula que oferecemos. Temos
      barras de Pole Dance com 4 metros de altura, todas com opção de deixar
      como estática ou giratória, e temos todos os equipamentos necessários para
      a prática de Pilates de aparelhos, solo e aéreo, além de acessórios como
      bloquinhos, pesos, grip, entre outros.
    </p>
    <!-- end fxFlexOrder="1.2" pt -->
  </content>
  <!-- end fxFlexOrder="1" -->
</main>
