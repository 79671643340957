<main
  id="content"
  class="content"
  [class]="global.contentDisplay"
  [class.portrait]="global.isPortrait"
  [class.landscape]="global.isLandscape"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.xs="start start"
  fxLayoutAlign.sm="start start"
  (window:resize)="onResizedModalities()"
>
  <nav
    fxFlexOrder="1"
    fxFlexOffset="300px"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxFlexOffset.xs="0"
    fxLayoutAlign.xs="center start"
    fxLayoutGap.xs="0"
    fxFlexOffset.sm="0"
    fxLayoutAlign.sm="center start"
    fxLayoutGap.sm="0"
  >
    <div *ngFor="let modality of global.modalities; index as i">
      <div
        *ngIf="modality.active"
        fxFlexOrder="{{ i + 1 }}"
        id="{{ modality.idName }}"
        class="modality-card"
        (click)="scripts.redirectAnimatePageOut(modality.path)"
      >
        <div
          *ngIf="global.lang === 'en'"
          alt="Modality item"
          ngClass="modality-card-title"
          [class.double]="modality.doubleTitle"
        >
          {{ modality.name.en }}
        </div>
        <div
          *ngIf="global.lang === 'pt'"
          alt="Modalidade item"
          ngClass="modality-card-title"
          [class.double]="modality.doubleTitle"
        >
          {{ modality.name.pt }}
        </div>
      </div>
    </div>
  </nav>
  <!-- end fxFlexOrder="1" -->
</main>
<!-- end #content .content -->
