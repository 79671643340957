<div
  id="content"
  class="content"
  [class]="global.contentDisplay"
  [class.portrait]="global.isPortrait"
  [class.landscape]="global.isLandscape"
  fxLayout="row"
  fxLayoutAlign="start start"
  fxLayoutAlign.xs="start start"
  (window:resize)="onResizedContact()"
>
  <div
    fxFlexOrder="1"
    fxFlex
    fxFlexOffset="300px"
    fxFlexOffset.xs="0"
    fxFlexOffset.sm="0"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="20px"
    fxLayout.xs="column"
    fxLayoutAlign.xs="start strech"
  >
    <div
      fxFlexOrder="1"
      fxFlex="35%"
      fxLayout="column"
      fxLayoutAlign="start strech"
      fxLayoutGap="20px"
    >
      <div fxFlexOrder="1" class="subtitle center">
        <span *ngIf="global.lang === 'en'">
          Social Networks
        </span>
        <span *ngIf="global.lang === 'pt'">
          Redes Sociais
        </span>
      </div>
      <!-- end fxFlexOrder="1.1" -->

      <div fxFlexOrder="2" class="center" fxHide.xs fxHide.sm>
        <span *ngIf="global.lang === 'en'">
          You can find us in the most popular social networks. Contact us
          through Whatsapp, Instagram and Facebook.
        </span>
        <span *ngIf="global.lang === 'pt'">
          Estamos presentes nas principais redes sociais. Você pode entrar em
          contato conosco através do Whatsapp, Instagram e Facebook.
        </span>
      </div>
      <!-- end fxFlexOrder 1.2 -->

      <div
        fxFlexOrder="3"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="30px"
      >
        <div fxFlexOrder="1" ngClass="social-icon">
          <a
            class="hint--bottom"
            aria-label="Whatsapp"
            alt="Whatsapp"
            href="https://api.whatsapp.com/send?1=pt_BR&phone=5513997416202"
            target="_blank"
          >
            <i class="fab fa-whatsapp"></i>
          </a>
        </div>
        <!-- end fxFlexOrder 1.3.1 -->

        <div fxFlexOrder="2" ngClass="social-icon">
          <a
            class="hint--bottom"
            aria-label="Instagram"
            alt="Instagram"
            href="https://www.instagram.com/fisio_pole"
            target="_blank"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
        <!-- end fxFlexOrder 1.3.2 -->

        <div fxFlexOrder="3" ngClass="social-icon">
          <a
            class="hint--bottom"
            aria-label="Facebook"
            alt="Facebook"
            href="https://www.facebook.com/fisiopoleart"
            target="_blank"
          >
            <i class="fab fa-facebook"></i>
          </a>
        </div>
        <!-- end fxFlexOrder 1.3.3 -->
      </div>
      <!-- end fxFlexOrder 1.3 -->

      <div
        fxFlexOrder="4"
        class="subtitle center"
        [class.space-top-30]="global.isDesktop"
        [class.space-top-10]="global.isSmartphone"
        [class.space-top-10]="global.isTablet"
      >
        <span *ngIf="global.lang === 'en'">Phone</span>
        <span *ngIf="global.lang === 'pt'">Telefone</span>
      </div>
      <!-- end fxFlexOrder="1.4" -->

      <div fxFlexOrder="5" class="center">
        <a href="tel:+551333263859">+55 13 3326-3859</a>
      </div>
      <!-- end fxFlexOrder="1.5" -->
    </div>
    <!-- end fxFlexOrder="1" -->

    <div
      fxFlexOrder="2"
      fxFlex="65%"
      fxLayout="column"
      fxLayoutAlign="start strech"
      fxLayoutGap="20px"
    >
      <div fxFlexOrder="1" class="subtitle center">
        <span *ngIf="global.lang === 'en'">Address</span>
        <span *ngIf="global.lang === 'pt'">Endereço</span>
      </div>
      <!-- end fxFlexOrder="2.1" en -->

      <div fxFlexOrder="2" class="center">
        <a
          href="https://maps.google.com/maps?q=Fisiopole%20Santos"
          target="_blank"
        >
          Av. Ana Costa, 521 - Salas 1 e 2, Gonzaga, Santos - SP, 11060-002
        </a>
      </div>
      <!-- end fxFlexOrder="2.2" en -->

      <div
        fxFlexOrder="3"
        class="icon center map-icon"
        fxHide.md
        fxHide.lg
        fxHide.xl
      >
        <a
          href="https://maps.google.com/maps?q=Fisiopole%20Santos"
          target="_blank"
        >
          <i class="fas fa-map-marked-alt"></i>
        </a>
      </div>
      <!-- end fxFlexOrder="2.3" en -->

      <div fxFlexOrder="4" fxFlex="100%" fxHide.xs fxHide.sm>
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              width="100%"
              height="350px"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Fisiopole%20Santos&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
          <!-- end#gmap_canvas -->
        </div>
        <!-- end #mapouter -->
      </div>
      <!-- end fxFlexOrder="2.4" en -->
    </div>
    <!-- end fxFlexOrder="2" -->
  </div>
  <!-- emd fx -->
</div>
