<main
  id="content"
  class="content"
  [class]="global.contentDisplay"
  [class.portrait]="global.isPortrait"
  [class.landscape]="global.isLandscape"
  fxLayout.xs="row"
  fxLayoutAlign.xs="center center"
  fxLayout.sm="row"
  fxLayoutAlign.sm="center center"
  (window:resize)="onResizedHome()"
>
  <content fxFlexOrder="1" id="text" fxFlex.xs="75%" fxFlex.sm="75%">
    <p *ngIf="global.lang === 'en'">
      FisioPole is a Pilates and Pole Dance studio located in the heart of
      Gonzaga in Santos since 2013. Use the menu above to learn more about our
      studio and our different classes.
    </p>

    <p *ngIf="global.lang === 'pt'">
      FisioPole é um estúdio de Pilates e Pole Dance localizado no coração do
      Gonzaga em Santos desde 2013. Utilize o menu acima para saber mais sobre
      nosso estúdio e nossas diversas modalidades.
    </p>
  </content>
  <!-- fxFlexOrder="1" -->
</main>
