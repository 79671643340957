<main
  id="content"
  class="content"
  [class]="global.contentDisplay"
  [class.portrait]="global.isPortrait"
  [class.landscape]="global.isLandscape"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.xs="start start"
  fxLayoutAlign.sm="start start"
  (window:resize)="onResizedTeam()"
>
  <nav
    fxFlexOrder="1"
    fxFlexOffset="300px"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxFlexOffset.xs="0"
    fxLayoutAlign.xs="center start"
    fxLayoutGap.xs="0"
    fxFlexOffset.sm="0"
    fxLayoutAlign.sm="center start"
    fxLayoutGap.sm="0"
  >
    <div *ngFor="let instructor of global.instructors; index as i">
      <div
        *ngIf="instructor.active"
        fxFlexOrder="{{ i + 1 }}"
        class="team-card"
      >
        <div
          class="team-card-photo"
          (click)="scripts.redirectAnimatePageOut(instructor.path)"
        >
          <img
            src="{{ instructor.photoPerfil }}"
            alt="Foto perfil {{ instructor.name }} {{ instructor.surname }}"
          />
        </div>
        <div class="team-card-title">
          <div>{{ instructor.name }}</div>
          <div>{{ instructor.surname }}</div>
        </div>
        <div class="team-card-content">
          <div class="team-card-content-social">
            <div *ngFor="let social of instructor.social">
              <a href="{{ social.url }}" target="_blank"
                ><i class="{{ social.icon }}"></i
              ></a>
            </div>
          </div>
          <div
            class="team-card-content-text"
            (click)="scripts.redirectAnimatePageOut(instructor.path)"
          >
            Saiba mais
          </div>
        </div>
      </div>
      <!-- end fxFlexOrder="1.*" .card -->
    </div>
  </nav>
  <!-- end fxFlexOrder="1" -->
</main>
